import { EnvironmentProviders, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { provideLocationsStore } from '@scheduler-frontend/data-access-locations';
import { ActionsEffects } from './+state/actions.effects';
import { actionReducer, ACTIONS_FEATURE_KEY } from './+state/actions.reducer';
import { ActionsStoreService } from './actions-store.service';

export function provideActionsStore(): Provider | EnvironmentProviders[] {
  return [
    provideLocationsStore(),
    provideState({ name: ACTIONS_FEATURE_KEY, reducer: actionReducer }),
    provideEffects(ActionsEffects),
    ActionsStoreService,
  ];
}
