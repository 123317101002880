import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@scheduler-frontend/environments';
import { Collection } from '@techniek-team/api-platform';
import { Observable } from 'rxjs';
import { ActionDetailContract } from '../../contracts/action.contract';

@Injectable({
  providedIn: 'root',
})
export class LoadActionsApi {
  protected readonly httpClient = inject(HttpClient);

  public execute(page: number, locationId: string): Observable<Collection<ActionDetailContract>> {
    let url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/actions`;
    let params: HttpParams = new HttpParams({
      fromObject: {
        'page': page,
        'itemsPerPage': 5,
        'slot.lesson.location': locationId,
      },
    });
    return this.httpClient.get<Collection<ActionDetailContract>>(url, {
      params: params,
    });
  }
}
